.dashboard {
    background: var(--white);
    border-radius: 30px;
    width: 900px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;
}

.container {
    background: var(--white);
    display: flex;
    justify-content: space-between;

    h1 {
        font-size: 1.3em;
    }
}

.editar_categorias {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    color: var(--gray700);
}

.cabecalho {
    background: var(--gray300);
    height: 50px;
    display: flex;
    justify-content: space-around;
    border-radius: 10px;

    .filtro {
        width: 50%;
        height: 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;

        &.active {
            background: var(--primary);
            color: var(--white);
        }
    }
}
