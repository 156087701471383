.dashboards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: 15px;

    > div {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }
}

