.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 1px;
}



.creater {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: var(--white);
    border-radius: 10px;
    padding: 20px;

}

.header {
    font-size: 1.4em;
}

