.header {
    background: var(--primary);
}

.hedear_container {
    display: flex;
    justify-content: space-between;
    padding: 2px;

}

.header_logo img {
    width: 50px;
}

.hedear_menu {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    text-decoration: none;
    color: var(--white);
    align-content: center;
    flex-wrap: wrap;

    a {
        color: var(--white);

        &:hover {
            color: var(--black);
        }
    }
}

.menu_superior {
    display: flex;
    gap: 30px;
    align-items: center;

    a {
        color: var(--white);
    }
}
