.modal {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 900;
    padding: 8px;
}

.modal_container {
    background: var(--white);
    border-radius: 5px;
    width: 600px;
    height: 400px;
    max-width: 100%;
    max-height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: auto;
}

.modal_container::-webkit-scrollbar {
    width: 8px;
    background-color: var(--gray);
}

.modal_container::-webkit-scrollbar-thumb {
    background-color: var(--primary);
}

.modal_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--gray);
}