.info_gerais {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    border-radius: 10px 10px 0 0 ;
}

.info_gerais_topo {
    background-color: var(--white300);
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    border-radius: 10px 10px 0 0 ;
}

.info_gerais_atalhos {
    float: inline-end;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    align-items: center;
    border-radius: 10px 10px 0 0 ;
}

.info_gerias_atalho {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    background: var(--white300);
    cursor: pointer;
    padding: 12px;
    width: 80px;
    border-radius: 10px 10px 0 0 ;
}

.info_gerais_resumo {
    float: inline-start;
    background-color: var(--white300);
    display: flex;
    gap: 12px;
    justify-content: space-between;
    padding: 0 30px;
    border-radius: 10px 10px 0 0 ;
}

.info_gerais_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px 10px 0 0 ;
}