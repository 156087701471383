@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #0878dd;
    --secondary: #abc;
    --info: #0878dd;
    --danger: #e74c3c;
    --warning: #ffbb33;
    --green: #2ab64f;
    --white: #ffffff;
    --white300: #fffafa;
    --gray: #f5f5f5;
    --gray300: #e3e3e3;
    --gray400: #dbc2c2;
    --gray700: #5d5d5d;
    --black: #000000;
    --blue: #3d3af1;
    --danger1: #ad635b;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--gray300);
}

#root {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: 100vh;
}

main {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
}

.container {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
}

.w-100 {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: var(--primary);
}

.btn {
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

.btn_more {
    border: none;
    border-radius: 10px;
    padding: 8px 16px;
    cursor: pointer;
    opacity: 0.8;
    font-size: 1em;
}

.btn-bold {
    font-weight: 600;
}

.btn-success {
    background: var(--green);
    color: var(--white);
}

.btn-danger {
    background: var(--danger);
    color: var(--white);
}

.bg-white {
    background: var(--white);
}

.bg-gray {
    background: var(--gray);
}

.bg-success {
    background: var(--green);
}

.bg-danger {
    background: var(--danger);
}

.btn-gray {
    background: var(--gray);
    color: var(--black);
}

.text-color-danger {
    color: var(--danger);
}

.text-color-gray400 {
    color: var(--gray400);
}

.text-color-success {
    color: var(--green);
}

input {
    border: solid 1px var(--black);
    border-radius: 6px;
    padding: 5px;
}

.hidden {
    display: none !important;
}