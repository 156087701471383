.dashboard_login {
    min-height: 100vh;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    background:var(--white);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
}

.logo {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.set_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px;
    color: var(--gray700);
    font-size: 0.9em;
}