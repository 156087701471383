.dashboard_cartao {
    background: var(--white);
    border-radius: 10px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
}

.titulo {
    background: var(--white);
    border-radius: 30px;
    width: 960px;
    height: 50px;
    padding: 20px;
}

.dashboard_card {
    display: flex;
    justify-content: center;


}

.card_cartao {
    background: var(--gray);
    border-radius: 10px;
    width: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    margin: 40px;
    gap: 15px;
}

.editar_card {
    text-align: right;
    font-size: 20px;
    font-weight: 600;
}

.editar_card_val{
    font-size: 13px;
}

.card_nome{
    font-size: 17px;
}
