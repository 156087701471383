
.modal_corpo {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.modal_edit {
    display: flex;
    gap: 10px;
}

.botao {
    display: flex;
    justify-content: center;
}

.aviso {
    background: var(--gray300);
    border-radius: 30px;
    padding: 30px;
    text-align: center;

    .h4 {
        font-style: italic;
    }
}